// AppsShowcase.js
import React from 'react';

const apps = [
  // Add your app details here
  { name: 'Check In - Mood Journal', link: 'https://apps.apple.com/us/app/check-in-mood-journal/id6479778769', imageUrl: 'https://is1-ssl.mzstatic.com/image/thumb/Purple221/v4/63/4b/14/634b1403-0cdb-a7b7-4550-44f2e5d1c270/AppIcon-0-0-1x_U007emarketing-0-10-0-85-220.png/230x0w.webp' },
  { name: 'EmpowerHER', link: 'https://apps.apple.com/us/app/empowerher-affirmations/id6473678202', imageUrl: 'https://is1-ssl.mzstatic.com/image/thumb/Purple112/v4/8a/db/fa/8adbfa7d-4c5f-2ba2-f803-d22449b5971b/AppIcon-0-0-1x_U007epad-0-85-220.png/230x0w.webp' },
  { name: 'Sonic Analyzer', link: 'https://apps.apple.com/us/app/sonic-analyzer/id6464337686', imageUrl: 'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/d5/93/f3/d593f355-aa73-b194-2fc8-51e9967fcaa8/AppIcon-1x_U007emarketing-0-10-0-85-220.png/460x0w.webp' },
  // Add more apps as needed
];

const AppsShowcase = () => {
  return (
    <section className="bg-customBackground text-white py-20 md:px-40 font-digital">
      <h2 className="text-4xl font-bold mb-10 text-center">Apps I've Built</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
        {apps.map((app) => (
          <a href={app.link} key={app.name} className="app-tile flex flex-col items-center bg-gray-800 rounded-lg p-4 hover:bg-gray-700 transition-colors">
            <img src={app.imageUrl} alt={app.name} className="w-full h-48 object-cover rounded-md mb-4" />
            <span className="text-lg font-medium">{app.name}</span>
          </a>
        ))}
      </div>
    </section>
  );
};

export default AppsShowcase;
