// WorkExperience.js
import React from 'react';

const WorkExperience = () => {
  return (
    <section className="bg-black text-white py-12 px-4 md:px-20 font-digital">
      <h2 className="text-4xl font-bold mb-10 text-center pb-16">Work Experience</h2>
      <div className="space-y-8">
        <div className="work-item">
          <h3 className="text-xl font-bold text-left text-white mb-1">Software Engineer @ Nebula, a Wynden Stark company</h3>
          <p className="text-yellow-400 mb-2 text-left py-5">May 2021 - Jan 2024</p>
          <ul className="list-disc list-inside space-y-2 ml-4 text-white text-left py-3">
            <li>Developed and managed an ETL data pipeline using GCP Dataflow, Cloud Functions, and task queues.</li>
            <li>Implemented Keycloak for robust user authentication across distributed services.</li>
            <li>Led the development of the user signup process utilizing MongoDB, Python, and Flask, including multi-stage email notifications.</li>
          </ul>
        </div>
        <div className="work-item py-16">
          <h3 className="text-xl font-bold text-left text-white mb-1">Software Engineer @ Shay Consulting</h3>
          <p className="text-yellow-400 mb-2 text-left py-5">Sep 2020 - April 2021</p>
          <ul className="list-disc list-inside space-y-2 ml-4 text-white text-left py-3">
            <li>Built an excel-like reusable table library for custom client needs with native CSS / React components</li>
            <li>Created a testing infrastructure for 3 clients to automate their CICD pipeline and reduce post-bug deployments by 30%</li>
            <li>Ran queue jobs and integrated with twilio api in order to build a custom in-house server system</li>
            <li>Communicate with Product Owners, UX Designers, Project Managers, and C-Level employees to achieve successful delivery of client products</li>
          </ul>
        </div>
        <div className="work-item py-16">
          <h3 className="text-xl font-bold text-left text-white mb-1">Contract Software Engineer @ Two Sigma IQ</h3>
          <p className="text-yellow-400 mb-2 text-left py-5">Jan 2020 - Jul 2020</p>
          <ul className="list-disc list-inside space-y-2 ml-4 text-white text-left py-3">
            <li>Helped maintain and expand legacy system capabilities with Nodejs</li>
            <li>Learned proprietary functional programming language and helped to architect and develop 2.0 release</li>
            <li>Used Datadog to run daily diagnostics checks and created multiple custom dashboards to monitor system health</li>
          </ul>
        </div>
        {/* Add more experiences as needed */}
      </div>
    </section>
  );
};

export default WorkExperience;
