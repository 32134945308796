import React from 'react';
import './App.css'; // Import your CSS here
import Bio from './components/Bio';
import WorkExperience from './components/WorkExperience';
import AppsShowcase from './components/AppsShowcase';

function App() {
  return (
    <div className="App">
      <main>
        <Bio />
        <WorkExperience />
        <AppsShowcase />
      </main>
    </div>
  );
}

export default App;
