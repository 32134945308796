// Bio.js
import React from 'react';
import { FaTwitter, FaGithub } from 'react-icons/fa'; // Import icons from react-icons

const Bio = () => {
  return (
    // Use responsive padding, smaller on mobile (p-5) and larger on larger screens (md:p-20)
    <section className="bg-customBackground text-white p-5 md:p-20 text-center font-digital">
      <p className="text-xl mb-8 md:mb-12 md:py-2">Hello world, I'm</p>
      <h1 className="text-4xl md:text-7xl my-8 md:my-12 py-16">Giancarlo Ferrer</h1>
      <p className="text-lg md:text-3xl mt-8 md:mt-12 mb-10 md:mb-14 py-20">Coding 💻. Lifting 🏋️‍♂️. Manga 📚. Vibing 🦜.</p>
      <div className="flex justify-center gap-4 mt-4 md:mt-8 md:pt-14">
      <a href="https://twitter.com/jio_ito" target="_blank" rel="noopener noreferrer" className="text-blue-500">
          <FaTwitter size={30} />
        </a>
        <a href="https://github.com/gferrer807" target="_blank" rel="noopener noreferrer" className="text-gray-300">
          <FaGithub size={30} />
        </a>
      </div>
    </section>
  );
};

export default Bio;

